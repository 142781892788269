import React, { useState } from 'react';
import { Switch, FormControlLabel, Box, Typography } from '@mui/material';

export default function SwitchToggle({loading, onSwitcher, checked, text}) {

  return (
   <div>
    <input disabled={loading} onChange={onSwitcher} checked={checked} type='checkbox' id='toggle' className='toggleCheckbox' />
    <label htmlFor='toggle' className='toggleContainer'>
    <div>{text.firstOption}</div> 
    <div>{text.secondOption}</div>
  </label>
  </div>
  );
}
import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar, CustomLoadingOverlay, SwitchToggle } from '../../components'
import { Link } from 'react-router-dom'
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey } from "../../utils/utils"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { useFetch, usePermissions } from '../../hooks'
import { applyFilters, checkSize } from '../../utils/utils'


export default function LatestEstimateDataWeeklyRaport({ raportType, onChangeRaportType }) {
  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, 'Activity_ID')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();

  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.LatestEstimateDataWeekly || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size && prefsData.reports_column_size.LatestEstimateDataWeekly || 'm');

  const columnVisibility = {};
  if (prefsData.reports_column_visibilty && prefsData.reports_column_visibilty.LatestEstimateDataWeekly) {
    prefsData.reports_column_visibilty.LatestEstimateDataWeekly.forEach(it => columnVisibility[it] = false);
  }


  const saveColumnVisibility = (data) => {
    saveUserPrefsWithoutRefresh({
      ...prefsData,
      reports_column_visibilty: {
        ...prefsData.reports_column_visibilty,
        LatestEstimateDataWeekly: Object.entries(data).filter(it => !it[1]).map(it => it[0])
      }
    })
    silentUpdateUserPrefs()
  }

  const [filter, setFilter] = useState([
    {
      field: "YEAR",
      title: 'Year',
      formatField: "year",
      valueFilter: applyFilters("YEAR", "latestEstimateDataWeekly"),
      firstKeyOption: 'YEAR',
      required: true,
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "SUBTOTAL_CODE",
      title: t("LatestEstimateData.SUBTOTAL_CODE"),
      inputText: t("LatestEstimateData.SUBTOTAL_CODE_INPUT") + ' *',
      firstKeyOption: "SUBTOTAL_CODE",
      secondKeyOption: "",
      valueFilter: applyFilters("SUBTOTAL_CODE", "latestEstimateDataWeekly"),
      changingArray: [],
      formatField: "select",
      required: true,
      noMultiple: true,
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "REFERENCE_ID",
      title: t("LatestEstimateData.REFERENCE_ID"),
      inputText: t("LatestEstimateData.REFERENCE_ID") + ' *',
      firstKeyOption: "REFERENCE_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("REFERENCE_ID", "latestEstimateDataWeekly"),
      changingArray: [],
      // atLeastThreeLetter: true,
      formatField: "select",
      required: true,
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "BP_NR",
      title: t("LatestEstimateData.BP_NR"),
      inputText: t("LatestEstimateData.BP_NR_INPUT"),
      firstKeyOption: "BP_NR",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("BP_NR", "latestEstimateDataWeekly"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "MATERIAL_NR",
      title: t("LatestEstimateData.MATERIAL_NR"),
      inputText: t("LatestEstimateData.MATERIAL_NR_INPUT"),
      firstKeyOption: "MATERIAL_NR",
      secondKeyOption: "",
      valueFilter: applyFilters("MATERIAL_NR", "latestEstimateDataWeekly"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
  ])

  useEffect(() => {

  }, [])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d, ind) => {
      return {
        id: items.length + ind,
        ...d
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [t])

  useEffect(() => {
    const requiredFilters = filter.filter(it => it.required).filter(it => !it.valueFilter|| (it.noMultiple&&!Object.keys(it.valueFilter).length)||(!it.noMultiple&&!it.valueFilter.length))

    var queryParams = {
      BUSINESS_UNIT: value_bu,
      limit: 100,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField , noMultiple} = key
      switch (formatField) {
        case "select":
          if( noMultiple &&valueFilter&&valueFilter[firstKeyOption]){
            queryParams[firstKeyOption] = valueFilter[firstKeyOption]
            break
          }
          if (valueFilter&&valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "year":
          if (valueFilter && valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      latestEstimateDataWeekly: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) {
      filterObj = JSON.parse(cachedfilters)
      filterObj["latestEstimateDataWeekly"] = queryParams
    }
    if (!filterObj["latestEstimateDataWeekly"]["YEAR"]) {
      const current_year = String(new Date().getFullYear())
      filterObj["latestEstimateDataWeekly"]["YEAR"] = current_year
      setFilter(filter.map(it => {
        if (it.field === "YEAR") {
          it.valueFilter = current_year
        }
        return it
      }))
    }
    if(filterObj["latestEstimateDataWeekly"]["SUBTOTAL_CODE"]){
      filterObj["latestEstimateDataWeekly"]["SUBTOTAL_CODE"] = {"SUBTOTAL_CODE": filterObj["latestEstimateDataWeekly"]["SUBTOTAL_CODE"]}
    }

    localStorage.setItem("filters", JSON.stringify(filterObj))
    if (requiredFilters.length) {
      setLoading(false)
      return

    }
    setLoading(true)

    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)
    request.fetchData(
      `${backend_url.latest_estimates_weekly}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
      .then(data => {
        setLoading(false)
        addLoadedRows(data.map(it => {
          it.BUSINESS_PARTNER = {
            BP_NR: it.BP_NR,
            BP_NAME: it.BP_NAME,
            BP_ROLE: it.BP_ROLE,
          }
          it.MATERIAL = {
            MATERIAL_NR: it.MATERIAL_NR,
            MATERIAL_NAME: it.MATERIAL_NAME,
          }
          it.UOM_CURRENCY = it.UOM || it.CURRENCY
          Object.entries(it.PERIODS).map(value => {
            if (value.length === 2) {
              it[value[0]] = value[1]
            }
          }
          )
          return it
        }))

        setCount(data.length)
      })

  }, [filter, page])

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
      silentUpdateUserPrefs()
    })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize && !loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }
const renderCellPeriods = ({ value }) => 
(<div className="MuiDataGrid-cellContent">
    <div  className='MuiDataGrid-cellContent'>
      {value ?
        numberFormatter(prefsData.decimal_notation).format(value.PLANNED).replaceAll(' ', ' ') :
        'n/a'}
    </div>
    <div  className='MuiDataGrid-cellContent'>
      {value ?
        numberFormatter(prefsData.decimal_notation).format(value.ACTUALIZED).replaceAll(' ', ' ') :
        'n/a'}
    </div>
  </div>)

const returnColumnPeriods = ()=>{
  const periods ={}
  for (let p=1; p<=13; p++){
    for(let w=1; w<=4; w++ ){
      periods[`P${p}W${w}`] = {
        field: `P${p}W${w}`,
        headerName: `P${p}W${w}`, 
        minWidth: 50,
        width: 80,
        cellClassName: 'le_weekly_row no_wrap right',
        renderCell: renderCellPeriods,
        sortComparator: (v1, v2) => {
          if(!v1&&v2) return -1
          if(v1&&!v2) return 1
          if(!v1&&!v2) return 0
          if (v1.PLANNED < v2.PLANNED) return -1;
          if (v1.PLANNED > v2.PLANNED) return 1;
          return 0;
        },
      }
    }
  }
  return periods
}

  const columns = React.useMemo(() => {
    const allColumns = {
      // id: { 
      //     field: 'id', 
      //     headerName: 'id' 
      // },
      REFERENCE_ID: {
        field: 'REFERENCE_ID',
        headerName: t("LatestEstimateData.REFERENCE_ID"),
        hideable: false,
        minWidth: 50,
        width: 80,
        hideable: false,
      },
      BUSINESS_PARTNER: {
        field: 'BUSINESS_PARTNER',
        headerName: t("LatestEstimateData.BUSINESS_PARTNER"),
        minWidth: 50,
        width: 130,
        hideable: false,
        cellClassName: 'le_weekly_row center',
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">
          <div className='MuiDataGrid-cellContent' >{value.BP_ROLE}{value.BP_NR}</div>
          <div className='MuiDataGrid-cellContent'>{value.BP_NAME}</div>
        </div>,
        sortComparator: (v1, v2) => {
          if (v1.BP_NR < v2.BP_NR) return -1;
          if (v1.BP_NR > v2.BP_NR) return 1;
          return 0;
        },

      },
      MATERIAL: {
        field: 'MATERIAL',
        headerName: t("LatestEstimateData.MATERIAL"),
        minWidth: 50,
        width: 130,
        hideable: false,
        cellClassName: 'le_weekly_row center',
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">
          <div className='MuiDataGrid-cellContent' >{value.MATERIAL_NR}</div>
          <div className='MuiDataGrid-cellContent'>{value.MATERIAL_NAME}</div>
        </div>,
        sortComparator: (v1, v2) => {
          if (v1.MATERIAL_NR < v2.MATERIAL_NR) return -1;
          if (v1.MATERIAL_NR > v2.MATERIAL_NR) return 1;
          return 0;
        },
      },
      UOM_CURRENCY: {
        field: 'UOM_CURRENCY',
        headerName: t("LatestEstimateData.UOM_CURRENCY"),
        minWidth: 50,
        width: 80,
      },
      PLANNED: {
        field: 'PLANNED',
        headerName: t("LatestEstimateData.PLANNED_ACTUALIZED"),
        minWidth: 50,
        width: 130,
        sortable: false,
        headerClassName: 'first_pinned_header', 
        cellClassName: 'le_weekly_row center first_pinned_row',
        renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">
          <div className='MuiDataGrid-cellContent' >{t("LatestEstimateData.PLANNED")}:</div>
          <div className='MuiDataGrid-cellContent'>{t("LatestEstimateData.ACTUALIZED")}:</div>
        </div>,
      },
      YTD: {
        field: 'YTD',
        headerName: t("LatestEstimateData.YTD"),
        minWidth: 50,
        width: 80,
        headerClassName: 'last_pinned_header', 
        cellClassName: 'le_weekly_row right no_wrap last_pinned_row',
        renderCell: ({ value }) => {
          const isBold = value ? value.PLANNED > value.ACTUALIZED ? 1 : value.PLANNED < value.ACTUALIZED ? 2 : null : null
          const styleBold = { fontWeight: 'bold' }
          return (<div className="MuiDataGrid-cellContent">
            <div style={isBold == 1 ? styleBold : {}} className="MuiDataGrid-cellContent">
              {value ?
                numberFormatter(prefsData.decimal_notation).format(value.PLANNED).replaceAll(' ', ' ') :
                'n/a'}
            </div>
            <div style={isBold == 2 ? styleBold : {}} className="MuiDataGrid-cellContent">
              {value ?
                numberFormatter(prefsData.decimal_notation).format(value.ACTUALIZED).replaceAll(' ', ' ') :
                'n/a'}
            </div>
          </div>)
        },
        sortComparator: (v1, v2) => {
          if (v1.PLANNED < v2.PLANNED) return -1;
          if (v1.PLANNED > v2.PLANNED) return 1;
          return 0;
        },
      },
      ...returnColumnPeriods(),
    }

    return orderedColumns.length ? orderedColumns.filter(it => allColumns[it]).reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []) : Object.values(allColumns);

  }, [orderedColumns]);

  // const handleColumnOrderChange = React.useCallback((params) => {
  //   setOrderedColumns((prevOrderedColumns) => {
  //     const newOrderedColumns = [...prevOrderedColumns];
  //     const oldIndex = params.oldIndex;
  //     const targetIndex = params.targetIndex;
  //     const oldColumn = prevOrderedColumns[oldIndex];
  //     newOrderedColumns.splice(oldIndex, 1);
  //     newOrderedColumns.splice(targetIndex, 0, oldColumn);
  //     saveUserPrefsWithoutRefresh({
  //       ...prefsData,
  //       reports_column_order: {
  //         ...prefsData.reports_column_order,
  //         LatestEstimateDataWeekly: newOrderedColumns
  //       }
  //     })
  //     return newOrderedColumns;
  //   });
  // }, []);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Menu.volumes"), link: '' },
    { label: t("Menu.latestEstimateData"), link: '/volumes/latest-estimate-data' },
  ]

  const onChangeFontSize = (value) => {
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size: {
        ...prefsData.reports_column_size,
        LatestEstimateDataWeekly: value
      }
    })
  }

  const onHandleSwitcher = () => {
    onChangeRaportType('standart')
  }

  return (
    <div className="tableContainer le_weekly">
      <DataGridPro
        apiRef={apiRef}
        sx={{
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height_double')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main': {
            fontSize: checkSize(fontSize, 'font_size'),
            minHeight: '150px',
            height: rows.length < 7 ? `${checkSize(fontSize, 'row_height_double')*rows.length + checkSize(fontSize, 'header_height')+ 30}px !important` : '50vh'
          },
          '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders': {
            boxShadow: 'none'
          }

        }}
        headerHeight={checkSize(fontSize, 'header_height')}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getRowHeight={() => 'auto'}
        // onColumnOrderChange={handleColumnOrderChange}
        disableColumnReorder
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility
          },
           pinnedColumns: { left: ['REFERENCE_ID', 'BUSINESS_PARTNER', 'MATERIAL', 'UOM_CURRENCY', 'PLANNED'], right: ['YTD'] } 
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.latestEstimateData") + ': Weekly Report',
            displayStandartReportButton: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter
                onFilter={changeFilterValues}
                dataFilter={filter}
                preload={backend_url.accrual_filters}
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.latest_estimates_excel_file + '?' + exportQuery,
              endpointCount: backend_url.latest_estimates_excel_count + '?' + exportQuery,
              fileName: "Latest Estimate Data"
            },
            extraButton: [
              {
                headerName: "",
                anotherButton: <div className='toolbarIconSection toolbarSwitch' key="down_manager">
                  <SwitchToggle
                    onSwitcher={onHandleSwitcher}
                    checked={false}
                    loading={loading}
                    text={{ firstOption: 'Weekly', secondOption: 'Standard' }}
                  />
                </div>,
                disabled: false,
              },
            ],
          }
        }}
      />
    </div>
  )
}

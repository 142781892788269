import React, { useState, useEffect, useCallback } from "react"
import { isValidDate, valivDateFormat } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { styled } from '@mui/material/styles'
import { useFetch, useDebouncedFilter} from '../../hooks'
import { StaticLoaderLinear } from '../../components'
import { dateTimeFormatter } from "../../utils/utils"
import { useUserPrefs } from '../../pages/UserPreferences/ProvideUserPrefs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//import { Mutex, Semaphore, withTimeout } from 'async-mutex'


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />


const AccordionSummary = styled(
  (props) => (
    <MuiAccordionSummary
      expandIcon={< ArrowRightIcon className='bpx-arrow-icon' />}
      {...props}
    />
  )
)(
  ({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  })
)


function CustomMultipleFilter({ onFilter, dataFilter, preload, loading, checkYear = "" }) {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { prefsData } = useUserPrefs()

  const [expanded, setExpanded] = useState('panel1')
  const [filter, setFilter] = useState(dataFilter)
  const [prevent, setPrevent] = useState(true)
  const debouncedChangeHandler = useDebouncedFilter();

  useEffect(() => { }, [filter])

  useEffect(() => {

    const resultPromise = Promise.all(
      dataFilter.map(key => {
        const { endpoint, endpointLS, queryKeyToTyping, formatField, translateJSON, atLeastThreeLetter, noObject, firstKeyOption, isBPUppercase } = key

        if (formatField === "select") {
          let link = endpoint;
          if (translateJSON) {
            return key
          }

          if (atLeastThreeLetter) {
            return key
          }

          if(endpointLS){
            endpointLS.map(it=>{
              const localStorageValue = JSON.parse(localStorage.getItem(it.field));
              if(localStorageValue && localStorageValue[it.value]){
                link+= `/${localStorageValue[it.value]}`;
              }
            })
          }

          const checkBPUppercase = isBPUppercase ? "BUSINESS_UNIT" : "business_unit"
          return request.fetchData(
            `${link}?filter=${queryKeyToTyping}&limit=20&${checkBPUppercase}=${value_bu}`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false,
            false
          )
            .then(data => {

              if (noObject) {
                const convert = data.map(arr =>
                  ({ [firstKeyOption]: arr })
                )
                return { ...key, "changingArray": convert }
              } else {
                return { ...key, "changingArray": data }
              }

            })

        }

        if (formatField === "autoSelect") {
          return key
        }

        if (formatField === "date"||formatField==='year') {
          return key
        }

      })
    )

    resultPromise.then(data => {
      setFilter(data)
      setPrevent(false)
    }).catch(err => {
      setPrevent(false)
    })


  }, [dataFilter])

  const [store, setStore] = useState([])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const mappingState = useCallback((filterKey, filterValue, objKey = "valueFilter", noObject=false) => {
    const changingState = filter.map(item => {
      if (item.firstKeyOption === filterKey) {
        if(noObject){
          return { ...item, [objKey]: filterValue.map(it=>({[filterKey]: it})) }
        }
        return { ...item, [objKey]: filterValue }
      }
      return item
    })

    setFilter(changingState)

    
    if (objKey === "valueFilter") {
      onFilter(changingState)
    }

  }, [filter, onFilter])

  
  const onChangeSelectFilter = useCallback((target, value) => {
    mappingState(target, value)
  }, [mappingState])


  const onChangeDateFilter = useCallback((value, typing, target) => {
    
    if (isValidDate(typing, prefsData && prefsData.date_format)) {
      let convertDate = valivDateFormat(typing, prefsData && prefsData.date_format)
      mappingState(target, convertDate)
    } else {
      if (typing === undefined) {
        mappingState(target, "",)
      } else {
        mappingState(target, null, "")
      }
    }

  }, [mappingState])


  const filterValueToObj = (value, filterName, translateJSON, arrayObject, isObject) => {
    let filterResult = []
    if (translateJSON||isObject) {
      return value
    }

    if (arrayObject) {
      value.map(v => {
        filterResult.push(v)
      })
    } else {
      value.map(v => {
        filterResult.push({ [filterName]: v })
      })
    }
    
    return filterResult
  }

  const filterValueNoMultiple = (value, filterName)=>{
    if(value&&value[filterName]){
      return value[filterName]
    }
    return null
  }

  const displayRenderOption = (destructDisplay, option, selected) => {
    const renderMap = destructDisplay.map( (optionItem, index) => {
      const { id, type, field, valueKey } = optionItem

      if (type === "date") {
        return (
          <div key={id} className={`subtotal-autoselect-checkbox ${selected ? "selected" : null}`}>
            <div className="subtotal-autoselect-name">
              {field}
            </div>
            <div className="subtotal-autoselect-dots" style={{ width: 101 }}>
              {dateTimeFormatter(option[valueKey], prefsData.date_format, prefsData.time_format, prefsData.time_zone)},
            </div>
          </div>
        )
      } else {
        return (
          <div key={id} className={`subtotal-autoselect-checkbox ${selected ? "selected" : null}`}>
            <div className="subtotal-autoselect-name">
              {field}
            </div>
            <div className="subtotal-autoselect-dots">
              { index + 1 === destructDisplay.length ? option[valueKey] : `${option[valueKey]},` }
            </div>
          </div>
        )
      }

    })

    return renderMap

  }

  useEffect(() => {

    
    if (store.length) {
      store.map(item => {
        if (item.statusFetch === "success") {
          mappingState(item.firstKeyOption, item.displayData, "changingArray", item.noObject)
        }
      })
    }
    

  }, [store])


  const displaySelectFilters = (
    title, inputText, firstKeyOption, changingArray,
    endpoint, secondKeyOption, queryKeyToTyping, translateJSON,
    atLeastThreeLetter, noObject, noMultiple, valueFilter, isBPUppercase,
    arrayObject, fetchResult, required, isObject
  ) => {
    
    const value = noMultiple ? filterValueNoMultiple(valueFilter, firstKeyOption) : filterValueToObj(valueFilter, firstKeyOption, translateJSON, arrayObject, isObject)
   
    return (
      <div className="subtotal-filter-section" key={firstKeyOption}>
        <div className="subtotal-filter-label">{title}</div>
        <div className="subtotal-filter-field">
          <Autocomplete
            value={value}
            freeSolo={true}
            multiple={noMultiple ? false : true}
            className="subtotal-filter-autocomplete"
            disabled={prevent}
            options={changingArray || []}
            onChange={(e, value) => {

              if(e.type==="keydown" && e.key==='Enter'){
                if(noMultiple){
                  if(changingArray.find(el=>el[firstKeyOption]&&value&&value.length&&el[firstKeyOption]===value)){
                    return onChangeSelectFilter(
                      firstKeyOption,
                      value
                    )
                  }
                }else if(changingArray.find(el=>el[firstKeyOption]&&value&&value.length&&el[firstKeyOption]===value.at(-1))){
     
                  return onChangeSelectFilter(
                    firstKeyOption,
                    [...value.slice(0, -1).map(v => translateJSON ? v : v[firstKeyOption]), value.at(-1)]
                  )
                }
                return
              }

              if (noMultiple) {
                return onChangeSelectFilter(
                  firstKeyOption,
                  value
                )
              } else if (arrayObject) {
            
                return onChangeSelectFilter(
                  firstKeyOption,
                  value
                )
              } else {
                return onChangeSelectFilter(
                  firstKeyOption,
                  value.map(v => translateJSON||isObject ? v : v[firstKeyOption])
                )
              }

            }}
            onInputChange={(event, newValue, condition) => {

                if (condition === "clear") {
                  return false 
                }

                if (translateJSON) {
                  return false
                }

                if (!atLeastThreeLetter&&!fetchResult) {
                  return false
                }
                
                const checkBPUppercase = isBPUppercase ? "BUSINESS_UNIT" : "business_unit" 

                function requestFunction() {

                  setStore(prev => {
                    return [
                      ...prev,
                      {
                        text: newValue,
                        statusFetch: "pending",
                        displayData: [],
                        firstKeyOption: firstKeyOption
                      }
                    ]
                  })

                  request.fetchData(
                    `${endpoint}${checkYear && '/' + checkYear}?${checkBPUppercase}=${value_bu}&filter=${queryKeyToTyping}&filter_pattern=${newValue}`,
                    'GET',
                    null,
                    false,
                    tokenBpxRequest,
                    false
                  )
                  .then(data => {
                    
                    setStore(prev => {
                      return prev.map((item) =>
                        item.text === newValue && item.firstKeyOption === firstKeyOption ? { ...item, statusFetch: "success", displayData: data, noObject } : item
                      )
                    })

                  })
                  .catch(err => {

                    setStore(prev => {
                      return prev.map((item) =>
                        item.text === newValue && item.firstKeyOption === firstKeyOption ? { ...item, statusFetch: "error", displayData: [], noObject } : item
                      )
                    })

                  })
                }
                

                if (newValue.length >= 3 && atLeastThreeLetter && condition === "input") {
                  debouncedChangeHandler(requestFunction)
                }

                if (!atLeastThreeLetter && condition === "input") {
                  debouncedChangeHandler(requestFunction)
                }

            }}
            disableCloseOnSelect
            getOptionLabel={(option) => {
              if (arrayObject) {
                return Object.values(option).join(' - ')
              } else {
                return Object.values(option).join('')
              }
            }}
            isOptionEqualToValue={(option, value) => {
              if (translateJSON) {
                return JSON.stringify(option) === JSON.stringify(value)
              }
              return option[firstKeyOption] === value[firstKeyOption]
            }}
            renderTags={(tagValue, getTagProps) => {
              if(isObject){
                return tagValue.map((option, index) => (
                  <Chip {...getTagProps({ index })} key={index} label={Object.values(option[firstKeyOption])} />
                ))
              }
              return tagValue.map((option, index) => (
                <Chip {...getTagProps({ index })} key={index} label={Object.values(option)} />
              ))
            }}
            renderOption={(props, option, { selected }) => {            
              const updatedProps = {
                ...props,
                onClick: () => { return },
              }

              let displayOption

              if (translateJSON) {
                displayOption = (
                  <div className="subtotal-filter-name">
                    {Object.values(option).join()}
                  </div>
                )
              } else if (option === "") {
                displayOption = ""
              } else {
                displayOption = (
                  <>
                    <div className="subtotal-filter-name">{option[firstKeyOption]}</div>
                    <div className="subtotal-filter-dots">{option[secondKeyOption]}</div>
                  </>
                )
              }
              const test = loading ? updatedProps : props

              if(noMultiple){
                return( <li {...props} key={props.key} className="subtotal-filter-list">
                  <span className="subtotal-filter-no-multiple">
                    {option[firstKeyOption]} {option[secondKeyOption]}
                  </span>
                  
                </li>
                )
              }

              return (
                <li {...test} key={props.key} className="subtotal-filter-list">
                  <Checkbox
                    icon={icon}
                    disabled={loading ? true : false}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    size="small"
                    sx={{
                      paddingTop: "3px",
                      paddingBottom: "3px",
                    }}
                  />
                  <div className="subtotal-filter-checkbox">
                    {displayOption}
                  </div>
                </li>
              )

            }}
            renderInput={(params) => {
              const sx=required &&
                (
                  !valueFilter ||
                  (noMultiple&&!Object.keys(valueFilter).length) ||
                  (!noMultiple&&!valueFilter.length)
                 ) ?{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  "& fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: "red !important",
                  },
                  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: "red",
                  },
                  "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: "red",
                  },
                },
              }:{}
              return (
                <TextField
                  sx={sx}
                  {...params}
                  placeholder={inputText}
               className="subtotal-filter-textfield"
                />
              )
            }}
          />
        </div>
      </div>
    )

  }


  const displayAutoSelectFilters = (
    title, inputText, firstKeyOption, changingArray,
    endpoint, secondKeyOption, queryKeyToTyping, translateJSON,
    atLeastThreeLetter, noObject, noMultiple, valueFilter, destructDisplay
  ) => {

    function isEmpty(obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
    
      return true
    }

    return (
      <div className="subtotal-filter-section" key={firstKeyOption}>
        <div className="subtotal-filter-label">{title}</div>
        <div className="subtotal-filter-field">
          <Autocomplete
            className="subtotal-filter-autocomplete"
            value={valueFilter}
            multiple={false}
            disableClearable
            onChange={(e, value) => onChangeSelectFilter(firstKeyOption, value)}
            getOptionLabel={(option) => {
              
              if (!isEmpty(option)) {
                const optionLabels = destructDisplay.map( (optionItem, index) => {
                  const { field, valueKey, type} = optionItem
                  
                  if (type === "date") 
                    return `${field} ${dateTimeFormatter(option[valueKey], prefsData.date_format, prefsData.time_format, prefsData.time_zone)}` 
                  else {
                    if (index + 1 === destructDisplay.length)
                      return `${field} ${option[valueKey]}` 
                    else 
                      return `${field} ${option[valueKey]}` 
                  } 
                  
                })

                return optionLabels.join(',')
              } else {
                return ""
              }
              
            }}
            options={changingArray || []}
            renderOption={(props, option, { selected }) => {
              const updatedProps = {
                ...props,
                onClick: () => { return },
              }
              
              const test = loading ? updatedProps : props

              return (
                <div {...test} className={`subtotal-autoselect-list ${selected ? "selected" : null}`} >
                  { displayRenderOption(destructDisplay, option, selected) }
                </div>
              )

            }}
            renderInput={ (params) => (
              <TextField 
                {...params} 
                className="subtotal-filter-textfield" 
              />
            )}
          />
        </div>
      </div>
    )

  }
  
  
  const displayDateFilters = (title, valueFilter, firstKeyOption, year) => {
    
    const selectedYear = checkYear || new Date().getFullYear();    
    const minDate = new Date(`${selectedYear}-01-01`);
    const maxDate = new Date(`${selectedYear}-12-31`);
    
    return (
      <div className="subtotal-filter-section" key={firstKeyOption}>
        <div className="subtotal-filter-label">{title}</div>
        <div className="subtotal-filter-field">
          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DesktopDatePicker
              //inputFormat="yyyy-MM-dd"
              inputFormat={year ? "yyyy" : prefsData && prefsData.date_format}
              //mask="____-__-__"
              views={year ? ['year'] : null}
              onChange={(value, event) => onChangeDateFilter(value, event, firstKeyOption)}
              value={valueFilter || null}              
              minDate={firstKeyOption === "DATE_FROM_EXFACTORY" || firstKeyOption === "DATE_TO_EXFACTORY" ? minDate : undefined}
              maxDate={firstKeyOption === "DATE_FROM_EXFACTORY" || firstKeyOption === "DATE_TO_EXFACTORY" ? maxDate : undefined}
              onAccept={(value) => {
                let convertDate
                
                if (year) {
                  convertDate = moment(new Date(value)).format("YYYY")
                } else {
                  convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                }

                mappingState(firstKeyOption, convertDate)
              }}
              renderInput={(params) =>
                <TextField
                  {...params} inputProps={
                    {
                      ...params.inputProps,
                      placeholder: year ? "yyyy" : prefsData && prefsData.date_format
                    }
                  }
                />
              }
            />
          </LocalizationProvider>
        </div>
      </div>
    )

  }

  const displayYearFilters = (title, valueFilter, firstKeyOption)=>{
    return (
      <div className="subtotal-filter-section" key={firstKeyOption}>
        <div className="subtotal-filter-label">{title}</div>
        <div className="subtotal-filter-field">
          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DatePicker
              //inputFormat="yyyy-MM-dd"
              // inputFormat={prefsData && prefsData.date_format}
              //mask="____-__-__"
              openTo="year"
              views={['year']}
              className='trade-filter'
              value={valueFilter || null}
              disabled={loading ? true : false}
              onChange={(value) => onChangeSelectFilter(firstKeyOption, String(new Date(value).getFullYear()))}
              renderInput={(params) =>
                <TextField
                  {...params} inputProps={
                    {
                      ...params.inputProps,
                    }
                  }
                />
              }
            />
          </LocalizationProvider>
        </div>
      </div>
    )
  }


  const displayFilters = () => {

    return filter.map(key => {
      const {
        endpoint, queryKeyToTyping, translateJSON, valueFilter,
        title, inputText, firstKeyOption, secondKeyOption, formatField, changingArray,
        atLeastThreeLetter, noMultiple, noObject, year, destructDisplay, isBPUppercase,
        arrayObject, fetchResult, required, isObject
      } = key
    
      switch (formatField) {
        case "select":
          return displaySelectFilters(
            title, inputText, firstKeyOption, changingArray,
            endpoint, secondKeyOption, queryKeyToTyping, translateJSON, atLeastThreeLetter,
            noObject, noMultiple, valueFilter, isBPUppercase, arrayObject, fetchResult, required, isObject
          )

        case "autoSelect":
          return displayAutoSelectFilters(
            title, inputText, firstKeyOption, changingArray,
            endpoint, secondKeyOption, queryKeyToTyping, translateJSON, atLeastThreeLetter,
            noObject, noMultiple, valueFilter, destructDisplay
          )

        case "date":
            return displayDateFilters(title, valueFilter, firstKeyOption, year)

        case 'year':
          return displayYearFilters(title, valueFilter, firstKeyOption)
        default:
      }

    })

  }
//  const debouncedChangeHandler = useCallback(
//     debounce(changeHandler, 500)
//   , []);

  return (
    <Accordion className="subtotal-accordion" expanded={expanded === 'panel1'} onChange={(handleChange('panel1'))}>
      <AccordionSummary>
        <div>{t("ActualDataInternal.SELECTION_CRITERIA")}</div>
      </AccordionSummary>
      <AccordionDetails>
        <StaticLoaderLinear display={prevent} />
        <div className="subtotal-filter-block">
          {displayFilters()}
        </div>
      </AccordionDetails>
    </Accordion>
  )

}

export default CustomMultipleFilter

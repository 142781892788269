import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function ModalRequest({ open, onClose, text }) {
  const { t } = useTranslation('translation');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ paddingTop: 0, position: 'relative' }}>
        <div className="close-for-modal-window position-smaller">

          <IconButton
            onClick={onClose}
            className="wrap-cancel-modal"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            textAlign: 'center',
            marginTop: '30px',
          }}
        >
          {text}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t('MODAL.BUTTONS.CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

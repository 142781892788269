import { serialize } from 'object-to-formdata'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { backend_url } from '../../settings'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLocalStorage } from '../index'
import { Mutex } from 'async-mutex'
import { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react';
import { useMsal } from "@azure/msal-react";

const isUrlAllowed = (url, allowedUrl) => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') return false;
    return Object.values(allowedUrl).some(domain => parsedUrl.href.includes(domain));
  } catch (err) {
    return false;
  }
}

export default function useFetch() {

  const navigate = useNavigate()
  const mutex = new Mutex()
  const { oktaAuth } = useOktaAuth();
  const { instance, accounts } = useMsal();

  let location = useLocation()

  const fetchData = async (
    url, method, data, formData = false, token = '',
    notistack = true, message = true, blob = false,
    type = false, upload = false, isHeader = false, isFile = false
  ) => {

    let headers = { 'accept': 'application/json' }

    if (token) {

      if (upload) {
        headers = {
          'Authorization': `Bearer ${token.slice(1, -1)}`,
        }
      } else {
        headers = {
          'Authorization': `Bearer ${token.slice(1, -1)}`,
          'accept': 'application/json',
        }
        if (!isFile) {
          headers['Content-Type'] = 'application/json'
        }
      }

    }
    if (formData) {
      data = serialize(data)
    }

    let response
    await mutex.runExclusive(async () => {
      if (!isUrlAllowed(url, backend_url)) {
        throw new Error("Wrong url")
      }

      response = await fetch(url, {
        method,
        headers,
        body: data ? data : null,
        responseType: blob ? "blob" : null,
      })


      if (response.status === 403) {
        let refreshToken = localStorage.getItem('refresh_token')
        let refreshOkta = JSON.parse(localStorage.getItem('refreshOkta'))
        const authenticationMethod = localStorage.getItem('authenticationMethod')

        if (JSON.parse(refreshToken) === null && refreshOkta) {
          refreshToken = JSON.parse(localStorage.getItem('okta-token-storage')).refreshToken
          await oktaAuth.token.renewTokens(refreshToken).then((tokens) => {
            oktaAuth.tokenManager.setTokens(tokens);
            localStorage.setItem("refreshOkta", false)
            return tokens
          }).then((tokens) => {
            if (!isUrlAllowed(url, backend_url)) {
              throw new Error("Wrong url")
            }
            response = fetch(url, {
              method,
              headers: {
                'Authorization': `Bearer ${tokens.accessToken.accessToken}`,
                'Content-Type': 'application/json',
                'accept': 'application/json'
              },
              body: data ? data : null,
              responseType: blob ? "blob" : null,
            }).then(r => {
              if (r.status === 403) {
                const from = location ? location.pathname : "/";
                localStorage.clear()

                navigate('/login')

                if (from.includes('provide_response')) {
                  localStorage.setItem('fromPage', from)
                }
                if (message)
                  SnackbarUtils.error(JSON.stringify('Your session has ended'))
                return false
              } else {
                return r.json().then(data => ({ status: r, body: data }))
              }
            })
          })
          return

        } else if (authenticationMethod && authenticationMethod == "MANUAL") {
          refreshToken = refreshToken.slice(1, -1)
        } else {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: accounts[0], 
          }).catch(()=>{
            const from = location ? location.pathname : "/";
              localStorage.clear()
  
              navigate('/supportlogin')
  
              if (from.includes('provide_response')) {
                localStorage.setItem('fromPage', from)
              }
              if (message)
                SnackbarUtils.error(JSON.stringify('Your session has ended'))
              return false
          });

          refreshToken = tokenResponse.idToken
        }
          if (!isUrlAllowed(url, backend_url)) {
            throw new Error("Wrong url")
          }
          response = await fetch(url, {
            method,
            headers: {
              'Authorization': `Bearer ${refreshToken}`,
              'Content-Type': 'application/json',
              'accept': 'application/json'
            },
            body: data ? data : null,
            responseType: blob ? "blob" : null,
          }).then(r => {
            if (r.status === 403) {
              const from = location ? location.pathname : "/";
              localStorage.clear()
              if (authenticationMethod && authenticationMethod == "MANUAL") {
                navigate('/login')
              } else {
                navigate('/supportlogin')
              }

              if (from.includes('provide_response')) {
                localStorage.setItem('fromPage', from)
              }
              if (message)
                SnackbarUtils.error(JSON.stringify('Your session has ended'))
              return false
            } else {
              localStorage.setItem('token_bpx', JSON.stringify(refreshToken))
              return r
            }
          })

      } else if (response.status === 500 && notistack) {
        SnackbarUtils.error(JSON.stringify('Server Error'))
      }
    })

    if (!response.headers.get("content-type")) {
      if (response.ok) {
        if (notistack) {
          SnackbarUtils.success(JSON.stringify('Successfully fetched'));
        }

        return true;
      } else {
        if (notistack) {
          SnackbarUtils.error(JSON.stringify(response.statusText));
        }
        throw new Error(response.status)
      }
    }

    const loginCondition = response.url && response.url.split('/').splice(-2).join('/') === 'backend/token'
    const responseBody = blob ? await response.blob() : await response.json()

    if (response.ok) {

      if (notistack) {
        SnackbarUtils.success(JSON.stringify('Successfully fetched'))
      }
      if (isHeader) {
        return {
          body: responseBody,
          title: response.headers.get('title'),
          logoName: response.headers.get('logo_name'),
          authenticationMethod: response.headers.get('authentication-method')
        }
      }
      return responseBody

    } else {

      if (notistack) {
        if (response.status === 409) {
          const start = responseBody.detail.indexOf('DETAIL');
          const end = responseBody.detail.indexOf('\n', start + 9);
          const message = end !== -1 && start !== -1
            ? responseBody.detail.slice(start + 9, end)
            : "Duplicate data";
          SnackbarUtils.error(message);
          throw new Error(response.status)
        } else if (responseBody.detail === 'Invalid crontab expression!') {
          SnackbarUtils.error(responseBody.detail);
          throw new Error(response.status)
        } else if (response.status === 404) {
          SnackbarUtils.error(JSON.stringify("Element not found"));
        } else if (loginCondition) {
          SnackbarUtils.error(JSON.stringify('Invalid user/password credentials'))
        } else if (responseBody.detail === 'Password present in password history' || responseBody.detail === 'Password has to be different than current one') {
          throw new Error('You had the same password before. Please add a new password')
        } else if (response.status === 422) {
          SnackbarUtils.error(JSON.stringify(response.statusText))
          throw new Error(JSON.stringify(response.statusText))
        }
        else {
          SnackbarUtils.error(JSON.stringify(response.statusText))
          throw new Error(JSON.stringify(response.statusText))
        }

      } else {
        if (response.status === 409) {
          if (responseBody.detail.includes("Wrong Currency")) {
            SnackbarUtils.error(responseBody.detail)
            throw new Error(response.status)
          }
        }
        //throw new Error(response.status)
        throw response.status
      }

    }


  }


  return { fetchData }

}

import debounce from 'lodash.debounce';
import { useCallback } from "react"

export default function useDebouncedFilter() {
  const debouncedChangeHandler = useCallback(
    debounce((func, ...args) => {
      func(...args);
    }, 1000),
    []
  );

  return debouncedChangeHandler;
}
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps, GridEvents } from '@mui/x-data-grid-pro';
import { unstable_composeClasses as composeClasses, Box, IconButton } from '@mui/material';
import { backend_url } from '../../settings'


export const isNavigationKey = (key) =>
  key === 'Home' ||
  key === 'End' ||
  key.indexOf('Arrow') === 0 ||
  key.indexOf('Page') === 0 ||
  key === ' ';

export const getTreeDataPath = (row) => row.hierarchy;

export const customer_hierarchy_data_fetcher = (val_bu, parentPath = [], token, filter, request) => {
  return new Promise((resolve) => {

    var queryParams, queryString

    // if (filter.dateFrom === null) return undefined

    // data without date / else when you have children rows (parmeters)
    if (parentPath.length === 0) {
      queryParams = {
        limit: 300,
        bp_role: 'CU',
        business_unit: val_bu,
        //date: new Date().toISOString().split('T')[0],
      }

      filter.map((key) => {
        const { valueFilter, firstKeyOption, formatField } = key
        switch(formatField) {
          case "select":
            if (valueFilter && valueFilter.length) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          case "date":
            if (valueFilter) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          default:
            return null
        }
      })

      // if (filter.dateFrom !== null) {queryParams['date'] = filter.dateFrom;}
      queryString = new URLSearchParams(queryParams).toString()
    } else {
      const par = parentPath.at(-1)
      queryParams = {
        limit: 300,
        bp_role: par[0],
        parent_bp_nr: par[1],
        parent_business_area: par[2],
        date: filter[0].valueFilter,
        business_unit: val_bu,
      }
      queryString = new URLSearchParams(queryParams).toString()
    }

    request.fetchData(
      `${backend_url.cust_hier_roots}?${queryString}`,
      'GET',
      null,
      false,
      token,
      false
    )
      .then(data => resolve(
        data.map(d => ({
          id: d.BP_ROLE + '.' + d.BP_NR + '.' + d.BUSINESS_AREA + '.' + d.DATE_FROM + '.' + d.DATE_TO,
          hierarchy: [...parentPath, [d.BP_ROLE, d.BP_NR, d.BUSINESS_AREA,  d.DATE_FROM, d.DATE_TO]],
          NAME1: d.bp.NAME1,
          NAME2: d.bp.NAME2,
          NAME3: d.bp.NAME3,
          CITY: d.bp.CITY,
          STREET_HOUSE_NUM: d.bp.STREET_HOUSE_NUM,
          POSTAL_CODE: d.bp.POSTAL_CODE,
          descendantCount: d.count_descendants,
          node_label: d.BP_NR,
          ...d
        }))
      ))
  })
}

export const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export const GroupingCellWithLazyLoading = (props) => {
  const { id, field, rowNode, row, hideDescendantCount, formattedValue } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const classes = useUtilityClasses({ classes: rootProps.classes });

  const Icon = rowNode.childrenExpanded
    ? rootProps.components.TreeDataCollapseIcon
    : rootProps.components.TreeDataExpandIcon;

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent(
        GridEvents.cellNavigationKeyDown,
        props,
        event
      );
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * 2 }}>
      <div className={classes.toggle}>
        {row.descendantCount > 0 && (
          <IconButton
            size="small"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
          >
            <Icon fontSize="inherit" />
          </IconButton>
        )}
      </div>
      <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
        {formattedValue === undefined ? row.node_label : row.node_label}
        {!hideDescendantCount && row.descendantCount > 0
          ? ` (${row.descendantCount})`
          : ''}
      </span>
    </Box>
  );
};

import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar, CustomLoadingOverlay, SwitchToggle } from '../../components'
import { Link } from 'react-router-dom'
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey } from "../../utils/utils"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { useFetch, usePermissions } from '../../hooks'
import { applyFilters, checkSize } from '../../utils/utils'

export default function LatestEstimateDataStandartRaport({raportType, onChangeRaportType}) {

  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, 'Activity_ID')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();

  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order&&prefsData.reports_column_order.LatestEstimateDataStandart || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.LatestEstimateDataStandart || 'm');

  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.LatestEstimateDataStandart){
    prefsData.reports_column_visibilty.LatestEstimateDataStandart.forEach(it=>columnVisibility[it]=false);
  }
  

  const saveColumnVisibility = (data)=>{
    saveUserPrefsWithoutRefresh({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        LatestEstimateDataStandart: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
    silentUpdateUserPrefs()
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "SUBTOTAL_CODE",
      title: t("LatestEstimateData.SUBTOTAL_CODE"),
      inputText: t("LatestEstimateData.SUBTOTAL_CODE_INPUT") + ' *',
      firstKeyOption: "SUBTOTAL_CODE",
      secondKeyOption: "",
      valueFilter: applyFilters("SUBTOTAL_CODE", "LatestEstimateDataStandart"),
      changingArray: [],
      formatField: "select",
      required: true,
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "REFERENCE_ID",
      title: t("LatestEstimateData.REFERENCE_ID"),
      inputText: t("LatestEstimateData.REFERENCE_ID_INPUT"),
      firstKeyOption: "REFERENCE_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("REFERENCE_ID", "LatestEstimateDataStandart"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "BP_NR",
      title: t("LatestEstimateData.BP_NR"),
      inputText: t("LatestEstimateData.BP_NR_INPUT"),
      firstKeyOption: "BP_NR",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("BP_NR", "LatestEstimateDataStandart"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
      //translateJSON: true,
      isObject: true,
    },
    {
      endpoint: backend_url.latest_estimates_filter_values,
      queryKeyToTyping: "MATERIAL_NR",
      title: t("LatestEstimateData.MATERIAL_NR"),
      inputText: t("LatestEstimateData.MATERIAL_NR_INPUT"),
      firstKeyOption: "MATERIAL_NR",
      secondKeyOption: "",
      valueFilter: applyFilters("MATERIAL_NR", "LatestEstimateDataStandart"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      firstKeyOption: "LE_DATE_FROM",
      title: t("LatestEstimateData.DATE_FROM"),
      valueFilter: applyFilters("LE_DATE_FROM", "LatestEstimateDataStandart"),
      formatField: "date"
    },
    {
      firstKeyOption: "LE_DATE_TO",
      title: t("LatestEstimateData.DATE_TO"),
      valueFilter: applyFilters("LE_DATE_TO", "LatestEstimateDataStandart"),
      formatField: "date"
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }
  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d, ind) => {
      return {
        id: items.length+ind,
        ...d   
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [t])

  const createBody = (params)=>{
    let result = {}
    Object.entries(params).map((item)=>{
      if(!item[1]||!item[1].length){
        return
      }
      switch(item[0]){
        case "REFERENCE_ID":
          result['REFERENCE_IDS'] = item[1]
          break;
        case "BP_NR":
          result["BP_NRS"] = item[1].map(bp=>[bp["BP_ROLE"], bp["BP_NR"]])
          break;
        case "MATERIAL_NR":
          result["MATERIAL_NRS"] = item[1]
          break;
        case "SUBTOTAL_CODE":
          result["SUBTOTAL_CODES"] = item[1]
          break;
        case "LE_DATE_FROM":
          result["DATE_FROM"] = item[1]
          break;
        case "LE_DATE_TO":
          result["DATE_TO"] = item[1]
          break;
      }
    })
    return JSON.stringify( {"body": {...result}})
  }

  useEffect(() => {
    const requiredFilters =  filter.filter(it=>it.required).filter(it=>!it.valueFilter.length)

    setLoading(true)

    var queryParams = {
      BUSINESS_UNIT: value_bu,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      latestEstimateData: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["LatestEstimateDataStandart"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    if (requiredFilters.length){
      setLoading(false)
      return

    }

    const queryString = getParam(queryParams).toString()

    
    setExportQuery(queryString)
    request.fetchData(
      `${backend_url.latest_estimates}?BUSINESS_UNIT=${value_bu}&limit=${100}&skip=${page}`,
      'POST',
      createBody(queryParams),
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data.map(it=>{
        if(it.CURRENCY){
          it.UOM = it.CURRENCY
        }else{
          it.UOM = it.BASE_UOM	
        }
        
        return it
      }))

      setCount(data.length)
    })

  }, [filter, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

    const columns = React.useMemo(() => {
      const allColumns = {
          // id: { 
          //     field: 'id', 
          //     headerName: 'id' 
          // },
          TRANSACTION_ID: { 
              field: 'TRANSACTION_ID', 
              headerName: t("LatestEstimateData.TRANSACTION_ID"), 
              hideable: false, 
              flex: 0.15,
              minWidth: 50,
          },
          BUSINESS_UNIT: { 
              field: 'BUSINESS_UNIT', 
              headerName: t("LatestEstimateData.BUSINESS_UNIT"), 
              flex: 0.08,
              minWidth: 50,
          },
          SUBTOTAL_CODE: { 
              field: 'SUBTOTAL_CODE',
              headerName: t("LatestEstimateData.SUBTOTAL_CODE"), 
              flex: 0.08,
              minWidth: 50,
          },
          REFERENCE_ID: { 
              field: 'REFERENCE_ID', 
              headerName: t("LatestEstimateData.REFERENCE_ID"), 
              flex: 0.07,
              minWidth: 50,
          },
          MATERIAL_NR: { 
              field: 'MATERIAL_NR', 
              headerName: t("LatestEstimateData.MATERIAL_NR"), 
              flex: 0.10,
              minWidth: 50,
          },
          BP_NR: { 
              field: 'BP_NR', 
              headerName: t("LatestEstimateData.BP_NR"), 
              flex: 0.10,
              minWidth: 50,
          },
          BP_ROLE: { 
              field: 'BP_ROLE', 
              headerName: t("LatestEstimateData.BP_ROLE"), 
              flex: 0.05,
              minWidth: 50,
          },
          LE_DATE: { 
              field: 'LE_DATE', 
              headerName: t("LatestEstimateData.LE_DATE"), 
              flex: 0.09,
              minWidth: 50,
             renderCell: ({ value }) => {
                return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone) }</div>
              },
          },
          UOM: { 
            field: 'UOM',
            headerName: t("LatestEstimateData.UOM") + ' / ' + t("LatestEstimateData.CURRENCY"), 
            flex: 0.07,
            minWidth: 50,

          },
          PLANNED: { 
              field: 'PLANNED',
              cellClassName: 'nowrapCell',
              headerName: t("LatestEstimateData.PLANNED"), 
              flex: 0.09,
              minWidth: 50,
              cellClassName:'wrapCell',
              renderCell: ({ value }) => {
                if (isNaN(value)) {
                  return value
                }
                return numberFormatter(prefsData.decimal_notation).format(value).replaceAll(' ', ' ');
              },
              align: "right",
          },
          ACTUALIZED: { 
            field: 'ACTUALIZED',
            cellClassName: 'nowrapCell',
            headerName: t("LatestEstimateData.ACTUALIZED"), 
            flex: 0.09,
            minWidth: 50,
            cellClassName:'wrapCell',
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return value
              }
              return numberFormatter(prefsData.decimal_notation).format(value).replaceAll(' ', ' ');
            },
            align: "right",       
        },
        LAST_UPDATED_AT: { 
          field: 'LAST_UPDATED_AT', 
          headerName: t("LatestEstimateData.LAST_UPDATED_AT"), 
          flex: 0.08,
          minWidth: 50,
         renderCell: ({ value }) => {
            return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone) }</div>
          },
      },
      }

      return  orderedColumns.length?orderedColumns.filter(it=>allColumns[it]).reduce((acc, field) => {
        return [...acc, allColumns[field]];
      }, []):Object.values(allColumns);
      
    }, [orderedColumns]);

    const handleColumnOrderChange = React.useCallback((params) => {
      setOrderedColumns((prevOrderedColumns) => {
        const newOrderedColumns = [...prevOrderedColumns];
        const oldIndex = params.oldIndex;
        const targetIndex = params.targetIndex;
        const oldColumn = prevOrderedColumns[oldIndex];
        newOrderedColumns.splice(oldIndex, 1);
        newOrderedColumns.splice(targetIndex, 0, oldColumn);
        saveUserPrefsWithoutRefresh({
          ...prefsData,
          reports_column_order: {
            ...prefsData.reports_column_order,
            LatestEstimateDataStandart: newOrderedColumns
          }
        })
        return newOrderedColumns;
      });
    }, []);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Menu.volumes"), link: '' },
    { label: t("Menu.latestEstimateData"), link: '/volumes/latest-estimate-data' },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        LatestEstimateDataStandart: value
      }
    })
  }
  
  const onHandleSwitcher = ()=>{
    onChangeRaportType('weekly')
  }
  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }

        }} 
        headerHeight={checkSize(fontSize, 'header_height')}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getRowHeight={() => 'auto'}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        initialState={{
          columns: {
            columnVisibilityModel:columnVisibility
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.latestEstimateData") + ': Standard Report',
            displayStandartReportButton: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                preload={backend_url.accrual_filters}
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.latest_estimates_excel_file + '?' + exportQuery,
              endpointCount: backend_url.latest_estimates_excel_count + '?' + exportQuery,
              fileName: "Latest Estimate Data"
            },
            extraButton: [
                {
                    headerName: "",
                    anotherButton: <div className='toolbarIconSection toolbarSwitch' key="down_manager">
                        <SwitchToggle 
                          onSwitcher={onHandleSwitcher} 
                          checked={true} 
                          loading={loading} 
                          text={{firstOption: 'Weekly', secondOption: 'Standard'}}                         />
                      </div>,
                    disabled: false,
                },
            ],
          }
        }}
      />
    </div>
  )
}

import { useTranslation } from "react-i18next"



export default function usePermissions () {

  const { t } = useTranslation("translation")
  const currentUserList = localStorage.getItem("current_user")
  let obj
  let childObj

  if (currentUserList) {
    obj = JSON
    .parse(currentUserList)
    .reports

    childObj = Object.keys(obj)
      .reduce((res, v) => res.concat(obj[v]), [])
      .reduce((a, v) => ({ ...a, [v]: v}), {})
   
  }

  function permission () {

    const activities = obj.hasOwnProperty("Activities")
      const myActivities = childObj.hasOwnProperty("My_Activities") && { 
        label: t("Menu.myActivities"), linkTo: "/my-activities/accrual_object" 
      }
      const activitySummary = childObj.hasOwnProperty("Activity_Summary") && { 
        label: t("Menu.activitySummary"), linkTo: "/my-activities/activity_summary" 
      }
      const tradeExpeditiureMonitor = childObj.hasOwnProperty("Trade_Expenditure_Monitor") && {
        label: t("Menu.tradeExpeditiureMonitor"), linkTo: "/my-activities/trade_expenditure_monitor" 
      }


    const accrualBalance = obj.hasOwnProperty("Accrual_Balance")
      const myBalance = childObj.hasOwnProperty("My_Balance") && { 
        label: t("Menu.myBalance"), linkTo: "/accrual-balance/my-balance"
      }
      const accrualPostingList = childObj.hasOwnProperty("Accrual_Postings") && { 
        label: t("Menu.accrualPostingList"), linkTo: "/accrual-balance/accrual_posting"
      }
      const accrualAdjustments = childObj.hasOwnProperty("Adjustments") && { 
        label: t("Menu.adjustments"), linkTo: "/accrual-balance/accrual-adjustments"
      }
      const reconciliationReport = childObj.hasOwnProperty("Full_Reconciliation") && { 
        label: "Reconciliation", linkTo: "/accrual-balance/reconciliation"
      }


    const payments = obj.hasOwnProperty("Payments")
      const myPayments = childObj.hasOwnProperty("My_Payments") && { 
        label: t("Menu.my_payments"), linkTo: "/payments/my-payments" 
      }

    const volumes = obj.hasOwnProperty("Volumes")
      const actualDataInternal = childObj.hasOwnProperty("Actual_Data_Internal") && { 
        label: t("Menu.actualDataInternal"), linkTo: "/volumes/actual-data/internal" 
      }
      const latestEstimateData = childObj.hasOwnProperty("Latest_Estimate_Data") && { 
        label:t("Menu.latestEstimateData"), linkTo: "/volumes/latest-estimate-data" 
      }


    const masterData = obj.hasOwnProperty("Master_Data")
      const businessPartners = childObj.hasOwnProperty("Business_Partners") && { 
        label: t("Menu.businessPartners"), linkTo: "/master-data/business_partners" 
      }
      const products = childObj.hasOwnProperty("Products") && { 
        label: t("Menu.products"), linkTo: "/master-data/products" 
      }
      const customerHierarchy = childObj.hasOwnProperty("Customer_Hierarchy") && { 
        label: t("Menu.customerHierarchy"), linkTo: "/master-data/customer_hierarchy" 
      }

    const support = obj.hasOwnProperty("Support")
      const errorCatalog = {
        label: t("Title.ErrorCatalog"), linkTo: "/support/error-catalog" 
      }

      const scheduler = {
        label: t("Menu.scheduler"), linkTo: "/support/scheduler" 
      }

      const workbench = {
        label: "Migration Workbench", linkTo: "/support/migration-workbench" 
      }

      const alertsTodos = {
        label: "Alerts and ToDo Manager", linkTo: "/support/alerts-todos-manager" 
      }

      
      const changelogs = {
        label: t("Menu.changeLogs"), linkTo: "/support/changelogs" 
      }


      const outboxes = {
        label: t("Menu.outboxes"), linkTo: "/support/outboxes" 
      }

    const customMenu = [
      activities ? {
        name: t("Menu.activities"),
        child: [
          myActivities,
          activitySummary,
          tradeExpeditiureMonitor
        ]
      } : null,

      accrualBalance ? {
        name: t("Menu.accrualBalance"),
        child: [
          myBalance,
          accrualPostingList,
          accrualAdjustments,
          reconciliationReport
        ]
      } : null,

      payments ? {
        name: t("Menu.payments"),
        child: [ 
          myPayments 
        ]
      } : null,

      volumes ? {
        name: t("Menu.volumes"),
        child: [
          actualDataInternal,
          latestEstimateData
        ]
      } : null,

      masterData ? {
        name: t("Menu.masterData"),
        child: [
          businessPartners,
          products,
          customerHierarchy,
        ]
      } : null,    

      support ? {
        name: t("Menu.support"),
        child: [
          scheduler,
          changelogs,
          alertsTodos,
          outboxes,
          // workbench
        ]
      } : null,

    ].filter(item => item !== null).map(sub => ({
      name: sub.name,
        child: sub.child.filter(o => o !== false)
    }))

    return customMenu

  }


  return { permission, obj, childObj }

}

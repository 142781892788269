import React, { useState} from 'react'
import LatestEstimateDataStandartRaport from './LatestEstimateDataStandartRaport'
import LatestEstimateDataWeeklyRaport from './LatestEstimateDataWeeklyRaport'

export default function LatestEstimateData() {
  const [state, setState]= useState('standart')
  return (
    <div className="tableContainer">
      {state==='standart'?
       <LatestEstimateDataStandartRaport raportType={state} onChangeRaportType={setState} />:
       <LatestEstimateDataWeeklyRaport raportType={state} onChangeRaportType={setState} />
      }
    </div>
  )
}

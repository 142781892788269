import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar } from '../../components'
import { Link } from 'react-router-dom'
import { getParam, dateTimeFormatter, checkSize } from "../../utils/utils"
import CustomPanel from '../../mui-components/CustomPanel'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { useFetch } from '../../hooks'
import { CustomLinkTable, CustomLoadingOverlay } from "../../components"
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import BuildCircleIcon from '@mui/icons-material/BuildCircle'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SnackbarUtils from '../../utils/SnackbarUtils'


export const AlertsTodosManager = () => {

    const apiRef = useGridApiRef()

    const { t } = useTranslation('translation')
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const value_bu = localStorage.getItem('bu_bpx')
    const userRoles = localStorage.getItem('userRoles')?localStorage.getItem('userRoles').split(","):''
  
    const request = useFetch()
  
    const [rows, setRows] = useState([])
    const [queryOptions, setQueryOptions] = useState({})
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
    const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.AlertsTodosManager || []);
    const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.AlertsTodosManager || 'm');

    const columnVisibility = {};
    if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.AlertsTodosManager){
        prefsData.reports_column_visibilty.AlertsTodosManager.forEach(it=>columnVisibility[it]=false);
    }

  
    const saveColumnVisibility = (data) => {
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_visibilty:{
          ...prefsData.reports_column_visibilty,
          AlertsTodosManager: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
        }
      })
      silentUpdateUserPrefs()
    }

    const handleReprocess = (alert_id) => {
        request.fetchData(
          `${backend_url.alert_reprocess}?business_unit=${value_bu}&alert_id=${alert_id}`,
          'POST',
          null,
          false,
          tokenBpxRequest,
          false,
          false
        )
        .then(data => {
            if (data) {
                SnackbarUtils.success(`Todo successfully created: ${data.alert_id}`)
            }
        })
    }

    const checkActionAccess = () => {
        if (userRoles?.includes("AE Full Access") || userRoles?.includes("Super User") || userRoles?.includes("System User")) {
            return true
        }
        return false
    }
  
    const actionsLinks = {
        REPROCESS: '/',
        MATERIALS: '/master-data/products',
        CONFIGURATION: '/configuration'
        // RETRY = "RETRY"
        // REPROCESS = "REPROCESS"
        // MATERIALS = "MATERIALS"
        // ACCRUAL_OBJECTS = "ACCRUAL_OBJECTS"
        // POSTINGS = "POSTINGS"
        // BUSINESS_PARTNERS = "BUSINESS_PARTNERS"
        // BUSINESS_UNITS = "BUSINESS_UNITS"
        // BUSINESS_SEGMENTS = "BUSINESS_SEGMENTS"
        // CONFIGURATION = "CONFIGURATION"
        // SUPPORT = "SUPPORT"
        // UOM = "UOM"
    }
  
    const columns = React.useMemo(() => {
        const allColumns = {
            processingStage: {
                field: 'processingStage',
                headerName: t("Home.ToDo.processing_stage"),
                flex: 0.08,
                minWidth: 30,
                renderCell: (params) => {
                    if (params.row.status === "processing" || params.row.status === "pending" || params.row.status === "triggered")
                        return <div className="MuiDataGrid-cellContent"><AccessTimeIcon fontSize='small'/></div>
                    else if (params.row.status === "new")
                        return <div className="MuiDataGrid-cellContent"><ReportProblemIcon color='error' fontSize='small'/></div>
                    else if (params.row.status === "closed")
                        return <div className="MuiDataGrid-cellContent"><DoneIcon color='success' fontSize='small'/></div>
                    else if (params.row.status === "error")
                        return <div className="MuiDataGrid-cellContent"><ErrorOutlineIcon color='error' fontSize='small'/></div>
                    else
                        return <div className="MuiDataGrid-cellContent"><BuildCircleIcon fontSize='small'/></div>
                }
            },
            created_at: {
                field: 'created_at',
                headerName: t("Home.ToDo.created_at"),
                flex: 0.08,
                minWidth: 80,
                renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.last_updated_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
            },
            process: {
                field: 'process',
                headerName: t("Home.ToDo.process"),
                flex: 0.08,
                minWidth: 50,
            },
            description: {
                field: 'description',
                headerName: t("Home.ToDo.description"),
                flex: 0.08,
                minWidth: 600,
                cellClassName: "description-cell"
            },
            alert_id: {
                field: 'alert_id',
                headerName: t("Home.ToDo.alert_id"),
                flex: 0.08,
                minWidth: 50
            },
            status: {
                field: 'status',
                headerName: t("Home.ToDo.status"),
                flex: 0.08,
                minWidth: 50
            },
            error_code: {
                field: 'error_code',
                headerName: t("Home.ToDo.error_code"),
                flex: 0.08,
                minWidth: 50
            },
            run_id: {
                field: 'run_id',
                headerName: t("Home.ToDo.run_id"),
                flex: 0.08,
                minWidth: 50
            },
            last_updated_at: {
                field: 'last_updated_at',
                headerName: t("Home.ToDo.last_updated_at"),
                flex: 0.08,
                minWidth: 80,
                renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.last_updated_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
            },
            updated_by: {
                field: 'updated_by',
                headerName: t("Home.ToDo.updated_by"),
                flex: 0.09,
                minWidth: 50,
            },
            actions: {
                field: "actions",
                type: "actions",
                headerName:t("Home.ToDo.action"),
                flex: 0.08,
                minWidth: 30,
                headerAlign: 'left',
                hideable: false,
                getActions: (params) => {
                    let actions = params.row.actions
                    if (params.row.status !== "new" && params.row.status !== "retry_failed") {
                        actions = actions?.filter(action => action !== "REPROCESS" && action !== "RETRY")
                    }

                    const links = actions ? actions.map((action) => {
                      if (action === "REPROCESS" || action === "RETRY")
                          return (
                              <a
                                  style={{ color: "#f69e80",textTransform: 'capitalize'}}
                                  onClick={() => handleReprocess(params.row.alert_id)}
                                  className="dark-mode-link-color"
                              >
                                  {action.toLowerCase()}
                              </a>
                          )
                      return (
                          <Link
                          style={{ color: "#f69e80", textDecoration:'none',textTransform: 'capitalize' }}
                          to={actionsLinks[action]}
                          className="dark-mode-link-color">
                              {action.toLowerCase()}
                          </Link>)
                      }) : []
        
                    return [
                    <CustomLinkTable
                        id={params.row.id}
                        links={links}
                    />
                    ]
                }
            }
    }
    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
        return [...acc, allColumns[field]];
        }, []):Object.values(allColumns);
        
    }, [orderedColumns]);
  
    const addLoadedRows = useCallback((items, rows) => {
      const changeIdRow = items.map((d, ind) => ({
        ...d,
        id: d._id,
        idx: rows.length + ind + 1,
        alert_id: d._id,
        process: d.dag_id
      }))
  
      setRows((old) => {
        return [...old, ...changeIdRow]
      })
  
    }, [queryOptions])
  
    useEffect(() => {
  
      const queryParams = {
        business_unit: value_bu,
        limit: 100,
        skip: page,
        ...queryOptions
      }
      const searchParam = getParam(queryParams).toString()
  
      request.fetchData(
        `${backend_url.alert_todo}?${searchParam}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
        .then(data => {
          addLoadedRows(data, rows)
          setCount(data.length)
        }) 
  
    }, [queryOptions, page])
  
    const handleOnRowsScrollEnd = (params) => {
      if (params.viewportPageSize) {
        if (count >= 100) {
          return setPage(page + 100)
        } else {
          return null
        }
      }
    }

    const handleColumnOrderChange = React.useCallback((params) => {
        setOrderedColumns((prevOrderedColumns) => {
          const newOrderedColumns = [...prevOrderedColumns];
          const oldIndex = params.oldIndex;
          const targetIndex = params.targetIndex;
          const oldColumn = prevOrderedColumns[oldIndex];
          newOrderedColumns.splice(oldIndex, 1);
          newOrderedColumns.splice(targetIndex, 0, oldColumn);
          saveUserPrefsWithoutRefresh({
            ...prefsData,
            reports_column_order: {
              ...prefsData.reports_column_order,
              AlertsTodosManager: newOrderedColumns
            }
          })
          return newOrderedColumns;
        });
      }, []);

    const objBreadcrumb = [
        { label: t("Breadcrumb.home"), link: '/' },
        { label: t("Menu.support"), link: '' },
        { label: t("Menu.alertsTodosManager"), link: '/support/alerts-todos-manager' },
    ]

    const onChangeFontSize = (value)=>{
      setFontSize(value)
      saveUserPrefs({
        ...prefsData,
        reports_column_size:{
          ...prefsData.reports_column_size,
          AlertsTodosManager: value
        }
      })
    }
    return (
        <div className="tableContainer">
            <DataGridPro
                apiRef={apiRef}
                sx={{ 
                '& .MuiDataGrid-row': {
                    minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                    height: 'auto !important'
                },
                '& .MuiDataGrid-cell': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                },
                '& .description-cell': {
                    whiteSpace: "normal !important", 
                    lineHeight: '1.5em'
                },
                '& .MuiDataGrid-main':{
                  fontSize: checkSize(fontSize, 'font_size')
                }
                }} 
                rows={rows}
                columns={columns}
                loading={loading}
                disableSelectionOnClick={true}
                sortingOrder={['desc', 'asc']}
                getRowHeight={() => 'auto'}
                headerHeight={checkSize(fontSize, 'header_height')}
                onColumnOrderChange={handleColumnOrderChange}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                onColumnVisibilityModelChange={saveColumnVisibility}
                initialState={{
                columns: {
                    columnVisibilityModel: {
                      actions: checkActionAccess(),
                      ...columnVisibility
                    }
                },
                }}
                localeText={{
                  noRowsLabel: t("Common.noRows")
                }}
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    Panel: CustomPanel
                }}
                componentsProps={{
                toolbar: {
                    bread: objBreadcrumb,
                    title: t("Title.alertsTodosManager"),
                    displayStandartReportButton: true,
                    exportSetting: {
                    },
                    showExport: false,
                    isFontSize: true,
                    fontSize: fontSize,
                    onChangeFontSize: onChangeFontSize,
                }
                }}
            />
      </div>
    )
}
import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar, CustomLoadingOverlay } from '../../components'
import { Link } from 'react-router-dom'
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey } from "../../utils/utils"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { useFetch, usePermissions } from '../../hooks'
import { applyFilters, checkSize } from '../../utils/utils'

export default function AOHeaderList() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, 'Activity_ID')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();

  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order&&prefsData.reports_column_order.My_Activites || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.My_Activites || 'm');

  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.My_Activites){
    prefsData.reports_column_visibilty.My_Activites.forEach(it=>columnVisibility[it]=false);
  }
  

  const saveColumnVisibility = (data)=>{
    saveUserPrefsWithoutRefresh({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        My_Activites: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
    silentUpdateUserPrefs()
  }


  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.accrual_filters,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("AOHeaderList.ACTIVITY_ID"),
      inputText: t("AOHeaderList.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "aoheaderList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.accrual_filters,
      queryKeyToTyping: "PLAN_ACCOUNT",
      title: t("AOHeaderList.PLAN_ACCOUNT_BP_NR"),
      inputText: t("AOHeaderList.PLAN_ACCOUNT_BP_NR_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT_BP_NR",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("PLAN_ACCOUNT_BP_NR", "aoheaderList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.accrual_filters,
      queryKeyToTyping: "AO_STATUS",
      title: t("AOHeaderList.AO_STATUS"),
      inputText: t("AOHeaderList.AO_STATUS_INPUT"),
      firstKeyOption: "AO_STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_STATUS", "aoheaderList"),
      changingArray: [],
      formatField: "select"
    },
    {
      firstKeyOption: "DATE_FROM_EXFACTORY",
      title: t("AOHeaderList.VALIDITY_FROM"),
      valueFilter: applyFilters("DATE_FROM_EXFACTORY", "aoheaderList"),
      formatField: "date"
    },
    {
      firstKeyOption: "DATE_TO_EXFACTORY",
      title: t("AOHeaderList.VALIDITY_TO"),
      valueFilter: applyFilters("DATE_TO_EXFACTORY", "aoheaderList"),
      formatField: "date"
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map(d => {
      return {
        id: d.AO_ID,
        ACTIVITY_ID: d.ACTIVITY_ID,
        ...d,
        PLAN_ACCOUNT_BP_NR: d.PLAN_ACCOUNT_BP_CAT + d.PLAN_ACCOUNT_BP_NR + " - " + d.NAME1,        
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [t])

  useEffect(() => {

    setLoading(true)

    var queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      aoheaderList: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["aoheaderList"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)
    request.fetchData(
      `${backend_url.accrual_object_header}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })

  }, [filter, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

    const columns = React.useMemo(() => {
      const allColumns = {
          id: { 
              field: 'id', 
              headerName: 'id' 
          },
          ACTIVITY_ID: { 
              field: 'ACTIVITY_ID', 
              headerName: t("AOHeaderList.ACTIVITY_ID"), 
              hideable: false, 
              flex: 0.12,
              minWidth: 50,
              renderCell: (params) => {
                if (checkActivityID) {
                  return (
                    <div className="MuiDataGrid-cellContent">
                      <Link style={{ color: '#f69e80' }} to={`/my-activities/accrual_object/${params.row.id}`} className="dark-mode-link-color">
                        {params.value}
                      </Link>
                    </div>                  
                  )
                }
              } 
          },
          AO_DESCRIPTION: { 
              field: 'AO_DESCRIPTION', 
              headerName: t("AOHeaderList.AO_DESCRIPTION"), 
              flex: 0.15,
              minWidth: 50,
          },
          AO_TYPE: { 
              field: 'AO_TYPE',
              headerName: t("AOHeaderList.AO_TYPE"), 
              flex: 0.11,
              minWidth: 50,
              renderHeader: ({colDef})=>{
                if(colDef.width < 100){
                  return (
                    <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="AO Type" 
                    data-mui-internal-clone-element="true">
                        {t("AOHeaderList.AO_TYPE_SHORT")}
                    </div>
                  )
                }
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Accrual Object Type" 
                    data-mui-internal-clone-element="true">
                      {t("AOHeaderList.AO_TYPE")}
                  </div>
                )
              },
          },
          EXT_CLASS: { 
              field: 'EXT_CLASS', 
              headerName: t("AOHeaderList.EXT_CLASS"), 
              flex: 0.08,
              minWidth: 50,
          },
          PLAN_ACCOUNT_BP_NR: { 
              field: 'PLAN_ACCOUNT_BP_NR', 
              headerName: t("AOHeaderList.PLAN_ACCOUNT_BP_NR_CONCAT"), 
              flex: 0.16,
              minWidth: 50,
              renderHeader: ({colDef})=>{
                if(colDef.width < 100){
                  return (
                    <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Plan account" 
                    data-mui-internal-clone-element="true">
                        {t("AOHeaderList.PLAN_ACCOUNT_BP_NR_SHORT")}
                    </div>
                  )
                }
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Plan account" 
                    data-mui-internal-clone-element="true">
                      {t("AOHeaderList.PLAN_ACCOUNT_BP_NR")}
                  </div>
                )
              },
          },
          PLAN_ACCOUNT_BP_CHANNEL: {},
          PLAN_ACCOUNT_BP_SUB_CHANNEL: {},
          DATE_FROM_EXFACTORY: { 
              field: 'DATE_FROM_EXFACTORY', 
              headerName: t("AOHeaderList.DATE_FROM_EXFACTORY"), 
              flex: 0.08,
              minWidth: 50,
              renderCell: ({ value }) => {
                return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
              },
          },
          DATE_TO_EXFACTORY: { 
              field: 'DATE_TO_EXFACTORY', 
              headerName: t("AOHeaderList.DATE_TO_EXFACTORY"), 
              flex: 0.08,
              minWidth: 50,
              renderCell: ({ value }) => {
                return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone) }</div>
              },
          },
          EXT_SPEND_TYPE: { 
              field: 'EXT_SPEND_TYPE', 
              headerName: t("AOHeaderList.EXT_SPEND_TYPE"), 
              flex: 0.08,
              minWidth: 50,
              renderCell: ({value})=>{
                if(!value){
                  return ''
                }
                return(
                  <div className="MuiDataGrid-cellContent">
                    {t("EXT_SPEND_TYPE." + value, { ns: 'mars_dictionaries' })}
                  </div>
                )
              }
          },
          ACCRUAL_BUDGET: { 
              field: 'ACCRUAL_BUDGET',
              headerName: t("AOHeaderList.ACCRUAL_BUDGET"), 
              flex: 0.08,
              minWidth: 50,
              cellClassName:'wrapCell',
              renderCell: ({ value }) => {
                if (isNaN(value)) {
                  return value
                }
                return numberFormatter(prefsData.decimal_notation).format(value).replaceAll(' ', ' ');
              },
              align: "right",
              renderHeader: ({colDef})=>{
                if(colDef.width < 100){
                  return (
                    <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Accrual Budget" 
                    data-mui-internal-clone-element="true">
                        {t("AOHeaderList.ACCRUAL_BUDGET_SHORT")}
                    </div>
                  )
                }
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Accrual Budget" 
                    data-mui-internal-clone-element="true">
                      {t("AOHeaderList.ACCRUAL_BUDGET")}
                  </div>
                )
              },
          },
          AO_STATUS: { 
              field: 'AO_STATUS', 
              cellClassName: 'nowrapCell',
              headerName: t("AOHeaderList.AO_STATUS"), 
              flex: 0.08,
              minWidth: 50,
          },
          INCOMING_BUSINESS_SEG: { 
              field: 'INCOMING_BUSINESS_SEG',
              cellClassName: 'nowrapCell',
              headerName: t("AOHeaderList.INCOMING_BUSINESS_SEG"), 
              flex: 0.08,
              minWidth: 50,
              renderHeader: ({colDef})=>{
                if(colDef.width < 100){
                  return (
                    <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Reporting Business Segment" 
                    data-mui-internal-clone-element="true">
                        {t("AOHeaderList.INCOMING_BUSINESS_SEG_SHORT")}
                    </div>
                  )
                }
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                    aria-label="Reporting Business Segment" 
                    data-mui-internal-clone-element="true">
                      {t("AOHeaderList.INCOMING_BUSINESS_SEG")}
                  </div>
                )
              },
              renderCell: ({value})=>{
                if(!value){
                  return ''
                }
                return(
                  <div className="MuiDataGrid-cellContent">
                   {value} -  {t("BUSINESS_SEGMENT." + value, { ns: 'mars_dictionaries' })}
                  </div>
                )
              }
          },
      }

      return  orderedColumns.length?orderedColumns.reduce((acc, field) => {
        return [...acc, allColumns[field]];
      }, []):Object.values(allColumns);
      
    }, [orderedColumns]);

    const handleColumnOrderChange = React.useCallback((params) => {
      setOrderedColumns((prevOrderedColumns) => {
        const newOrderedColumns = [...prevOrderedColumns];
        const oldIndex = params.oldIndex;
        const targetIndex = params.targetIndex;
        const oldColumn = prevOrderedColumns[oldIndex];
        newOrderedColumns.splice(oldIndex, 1);
        newOrderedColumns.splice(targetIndex, 0, oldColumn);
        saveUserPrefsWithoutRefresh({
          ...prefsData,
          reports_column_order: {
            ...prefsData.reports_column_order,
            My_Activites: newOrderedColumns
          }
        })
        return newOrderedColumns;
      });
    }, []);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Menu.activities"), link: '' },
    { label: t("Menu.myActivities"), link: '/my-activities/accrual_object' },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        My_Activites: value
      }
    })
  }
  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }

        }} 
        headerHeight={checkSize(fontSize, 'header_height')}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getRowHeight={() => 'auto'}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        initialState={{
          columns: {
            columnVisibilityModel:columnVisibility
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.accrualObjectHeader"),
            displayStandartReportButton: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                preload={backend_url.accrual_filters}
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.accrual_object_excel_file + '?' + exportQuery,
              endpointCount: backend_url.accrual_object_excel_count + '?' + exportQuery,
              fileName: "My Activities Report"
            } 
          }
        }}
      />
    </div>
  )
}

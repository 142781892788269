import React, { useEffect, useState } from "react"
import { useFetch } from '../../../hooks';
import { backend_url } from '../../../settings'
import Dialog from '@mui/material/Dialog'
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom"
import SnackbarUtils from '../../../utils/SnackbarUtils'
import { useTranslation } from 'react-i18next'

export default function ConfigurationSeparatePage({ table, business_unit }) {

  const { title } = table

  const [businessUnits, setBusinessUnits] = useState([])
  const [regionLogo, setRegionLogo] = useState({ src: '', slogan: '', isEmpty: true })
  const [businessUnitsLogo, setBusinessUnitsLogo] = useState([])
  const [open, setOpen] = useState(false)
  const [imageDisplay, setImageDisplay] = useState('')
  const [bg, setBg] = useState('light')
  const [loading, setLoading] = useState(true)
  const [updateData, setUpdateData] = useState({
    regionLogo:
    {
      image: '',
      type: '',
      logo: '',
      name: ''
    },
    businessUnits: {}
  })

  const urlCreator = window.URL || window.webkitURL;
  const { t } = useTranslation('translation')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  useEffect(() => {
    request.fetchData(
      backend_url.config_business_units + '?BUSINESS_UNIT=' + business_unit,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setBusinessUnits(data);
      const promises = []

      data.map(it => {
        promises.push({
          promise: request.fetchData(
            `${backend_url.config_business_units_logo}/${it.BUSINESS_UNIT}?MODE=dark`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false,
            false,
            true,

          ), businessUnit: it.BUSINESS_UNIT, mode: 'dark', name: it.LOGO_FILENAME_DARK
        })
        promises.push({
          promise: request.fetchData(
            `${backend_url.config_business_units_logo}/${it.BUSINESS_UNIT}?MODE=light`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false,
            false,
            true,
          ), businessUnit: it.BUSINESS_UNIT, mode: 'light', name: it.LOGO_FILENAME
        })
      })

      Promise.allSettled(promises.map(pr => pr.promise))
        .then(res => {
          setBusinessUnitsLogo(promises.map((it, ind) => {
            setLoading(false)
            let image = ''
            if (res[ind].status === "fulfilled" && res[ind].value.type.includes('image')) {
              image = urlCreator.createObjectURL(res[ind].value)
            }
            return { image, businessUnit: it.businessUnit, mode: it.mode, name: it.name }
          }))
        })
    })


    request.fetchData(
      backend_url.enterprise_identification,
      'GET',
      null,
      false,
      '',
      false,
      false,
      true,
      false,
      false,
      true
    ).then(data => {
      const src = data.body.type.includes('image') ? urlCreator.createObjectURL(data.body) : ''
      setRegionLogo({ slogan: data.title, src, isEmpty: data.title===null&&data.logoName===null, name: data.logoName })

      const file = data.logoName? new File([data.body], data.logoName, { type: "image/png" }) : ''
      setUpdateData(prev => ({
        ...prev, regionLogo: {
          ...prev.regionLogo,
          image: file,
          name: data.logoName
        }
      }))
    })
  }, [])



  useEffect(() => {
    if (imageDisplay) {
      setOpen(true)
    }

  }, [imageDisplay])

  const onClose = () => {
    setOpen(false)
    setImageDisplay('')
  }

  const onOpen = (src, bg) => {
    setBg(bg)
    setImageDisplay(src)
  }

  const onImageUpdate = (e) => {
    if (!e.target.files[0]) {
      return
    }
    const reader = new FileReader();
    reader.onload = (evn) => {
      const img = new Image();
      img.src = evn.target.result;
      img.onload = () => {
        if (e.target.name === 'regionLogo') {

          if (img.width > 285 || img.height > 105) {
            SnackbarUtils.error('This file is too big')
            return
          }
          setRegionLogo(prev => ({
            ...prev,
            src: urlCreator.createObjectURL(e.target.files[0]),
            name: e.target.files[0].name
          }))
          setUpdateData(prev => ({
            ...prev, regionLogo: {
              ...prev.regionLogo,
              image: e.target.files[0],
              isUpdate: true,
            }
          }))
        } else {

          if (img.width > 126 || img.height > 39) {
            SnackbarUtils.error('This file is too big')
            return
          }

          const keys = e.target.name.split('/');
          setBusinessUnitsLogo(prev => prev.map(it => {
            if (it.businessUnit === keys[0] && it.mode === keys[1]) {
              return {
                ...it,
                image: urlCreator.createObjectURL(e.target.files[0]),
                name: e.target.files[0].name
              }
            }
            return it
          }))
          setUpdateData((prev) => ({
            ...prev,
            businessUnits: {
              ...prev.businessUnits,
              [e.target.name]: {
                logo: e.target.files[0],
              }
            },
          }));
        }
      }
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  const onSave = () => {
    const promises = []
    if (updateData.regionLogo.isUpdate) {
      let title = updateData.regionLogo.slogan ? updateData.regionLogo.slogan : regionLogo.slogan;
      title = title ? title : ' ';
      const formData = new FormData();
      if (updateData.regionLogo.image){
        formData.append('logo', updateData.regionLogo.image);
      }     
      formData.append('title', title);
      const type = updateData.regionLogo.image ? updateData.regionLogo.image.type : 'image/png'
      formData.append('graphical_file_type', type);
      const metchod = regionLogo.isEmpty ? "POST" : "PUT"
      promises.push(request.fetchData(
        `${backend_url.enterprise_identification}?title=${title}&graphical_file_type=${type}`,
        metchod,
        formData,
        false,
        '',
        false,
        false,
      ))
    }
    if (Object.keys(updateData.businessUnits).length) {
      for (const [key, value] of Object.entries(updateData.businessUnits)) {
        const formData = new FormData();
        formData.append('logo', value.logo);
        formData.append('title', 'ggg');
        const type = value.logo ? value.logo.type : 'image/png'
        formData.append('type', type);
        const keys = key.split('/')
        promises.push(request.fetchData(
          `${backend_url.config_business_units_logo}/${keys[0]}?graphical_file_type=${type}&mode=${keys[1]}`,
          'PUT',
          formData,
          false,
          tokenBpxRequest,
          false,
          false,
          false,
          false,
          false,
          false,
          value.logo ? true : false
        ))
      }
    }
    Promise.all(promises).then(() => {
      SnackbarUtils.success('Data is updated');
      setUpdateData(prev => ({
        regionLogo: {
          ...prev.regionLogo,
          isUpdate: false,
        },
        businessUnits: {}
      }))
    })
      .catch(() => {
        SnackbarUtils.error('Server Error');
      })
  }

  const onChangeSlogan = (e) => {

    setUpdateData(prev => ({
      ...prev, regionLogo: {
        ...prev.regionLogo,
        slogan: e.target.value,
        isUpdate: true
      }
    }))
    setRegionLogo(prev => ({ ...prev, slogan: e.target.value }))
  }

  const onRemoveRegionPhoto = () => {
    setUpdateData(prev => ({
      ...prev, regionLogo: {
        ...prev.regionLogo,
        image: '',
        isUpdate: true
      }
    }))
    setRegionLogo(prev => ({
      ...prev,
      src: '',
      name: ''
    }))
  }

  const onRemoveBuPhoto = (bu) => {
    setUpdateData((prev) => ({
      ...prev,
      businessUnits: {
        ...prev.businessUnits,
        [bu]: '',
      },
    }));
    const keys = bu.split('/');

    setBusinessUnitsLogo(prev => prev.map(it => {
      if (it.businessUnit === keys[0] && it.mode === keys[1]) {
        return {
          ...it,
          image: '',
          name: ''
        }
      }
      return it
    }))
  }

  return (
    <div className='bpx-config-datagrid bpx-config-separate-page'>

      <h3 className='bpx-config-datagrid-title'>{title}</h3>

      <div >
        <b>{t("Config.ENTERPRISE_IDENTIFICATION.LOG_REG_SPEC")}</b>
      </div>

      <div className='grid-container'>
        <div className="grid-item"></div>
        <div className="grid-item file-name">{regionLogo.name}</div>
        <Button
          className="grid-item"
          onClick={() => onOpen(regionLogo.src, 'light')}
          disabled={!regionLogo.src || loading}
          variant="outlined"
          size="small"
        >
          Display
        </Button>
        <Button onClick={onRemoveRegionPhoto} disabled={!regionLogo.src || loading}
          variant="outlined"
          size="small"
          className="grid-item"
        >
          Remove
        </Button>
        <div className="grid-item" >
          <input
            type='file'
            accept="image/png, image/jpeg, image/jpg"
            name={'regionLogo'}

            onInput={onImageUpdate}
            id='regionLogo'
            className="file-input"
            disabled={loading}
          />
          <Button variant="outlined" size="small" disabled={loading}>
            <label htmlFor='regionLogo' className="file-input-label">
              <DownloadIcon sx={{ fontSize: '0.7rem' }} />
              Upload a File
            </label>
          </Button>
          {/* <div className='error' >{updateData.regionLogo.isError ? 'This file is too big. Please check max dimensions' : ''}</div> */}
        </div>
      </div>

      <div className="info" style={{ paddingTop: 0 }}>
        {t("Config.ENTERPRISE_IDENTIFICATION.LOG_REG_SIZE")}
      </div>

      <div className='grid-container' style={{ alignItems: 'center', marginTop: '15px' }}>
        <div className="grid-item">
          {t("Config.ENTERPRISE_IDENTIFICATION.SLOGAN")}
        </div>
        <FormControl size="small" className="grid-item" sx={{ gridColumn: '2/-1', width: '100% !important' }} >
          <TextField
            size="small"
            disabled={loading}
            inputProps={{ maxLength: 40, style: { textAlign: 'center' } }}
            value={updateData.regionLogo.isUpdate ? updateData.regionLogo.slogan : regionLogo.slogan}
            onInput={onChangeSlogan}
            placeholder={t("Config.ENTERPRISE_IDENTIFICATION.SLOGAN_INPUT")}
          />
        </FormControl>
      </div>

      <div className="info">
        {t("Config.ENTERPRISE_IDENTIFICATION.SLOGAN_SIZE")}
      </div>

      <h4 style={{ marginBottom: 0 }}>
        {t("Config.ENTERPRISE_IDENTIFICATION.LOG_BUS_UNIT_SPEC")}
      </h4>

      <div className='grid-container'>
        {businessUnits.map((it, ind) => {
          const imageLigth = businessUnitsLogo.find(el => el.businessUnit === it.BUSINESS_UNIT && el.mode == 'light') || {}
          const imageDark = businessUnitsLogo.find(el => el.businessUnit === it.BUSINESS_UNIT && el.mode == 'dark') || {}
          const bu = it.BUSINESS_UNIT
          return (<React.Fragment key={bu}>
            <div className="wide-column grid-item">{bu}</div>
            <div className="grid-item" style={{ paddingLeft: '20px' }} >
              {t("Config.ENTERPRISE_IDENTIFICATION.NORMAL_MODE")}
            </div>
            <div className="file-name grid-item">{imageLigth.name}</div>
            <Button
              className="grid-item"
              disabled={!imageLigth.image || loading}
              onClick={() => {
                onOpen(imageLigth.image, 'light')
              }}
              variant="outlined"
              size="small"
            >
              {t("MODAL.BUTTONS.DISPLAY")}
            </Button>
            <Button
              disabled={!imageLigth.image || loading}
              onClick={() => onRemoveBuPhoto(bu + '/light')}
              variant="outlined"
              size="small"
              className="grid-item"
            >
              {t("MODAL.BUTTONS.REMOVE")}
            </Button>
            <div className="grid-item" >
              <input
                disabled={loading}
                type='file'
                accept="image/png, image/jpeg, image/jpg"
                name={bu + '/light'} placeholder='fff'
                onInput={onImageUpdate}
                id={"file-input-light" + ind}
                className="file-input"
              />
              <Button variant="outlined" size="small" disabled={loading}>
                <label htmlFor={"file-input-light" + ind} className="file-input-label">
                  <DownloadIcon sx={{ fontSize: '0.7rem' }} />
                  {t("MODAL.BUTTONS.UPLOAD")}
                </label>
              </Button>
              {/* <div className='error'>{updateData.businessUnits[bu + '/light'] && updateData.businessUnits[bu + '/light'].isError ? 'This file is too big. Please check max. dimensions' : ''}</div> */}
            </div>

            <div className="grid-item" style={{ paddingLeft: '20px' }} >
              {t("Config.ENTERPRISE_IDENTIFICATION.DARK_MODE")}
            </div>

            <div className="file-name grid-item" >{imageDark.name}</div>
            <Button
              disabled={!imageDark.image || loading}
              onClick={() => {
                onOpen(imageDark.image, 'dark')
              }}
              size="small"
              variant="outlined"
              className="grid-item"
            >
              {t("MODAL.BUTTONS.DISPLAY")}
            </Button>
            <Button
              disabled={!imageDark.image || loading}
              onClick={() => onRemoveBuPhoto(bu + '/dark')}
              variant="outlined"
              size="small"
              className="grid-item"
            >
              {t("MODAL.BUTTONS.REMOVE")}
            </Button>
            <div className="grid-item" >
              <input
                type='file'
                disabled={loading}
                accept="image/png, image/svg, image/jpeg, image/jpg"
                name={bu + '/dark'}
                placeholder='fff'
                onInput={onImageUpdate}
                id={"file-input-dark" + ind}
                className="file-input"
              />
              <Button variant="outlined" size="small" disabled={loading}>
                <label htmlFor={"file-input-dark" + ind} className="file-input-label">
                  <DownloadIcon sx={{ fontSize: '0.7rem' }} />
                  {t("MODAL.BUTTONS.UPLOAD")}
                </label>
              </Button>
              {/* <div className='error'>{updateData.businessUnits[bu + '/dark'] && updateData.businessUnits[bu + '/dark'].isError ? 'This file is too big. Please check max. dimensions' : ''}</div> */}
            </div>
          </React.Fragment>
          )
        })}
      </div>

      <div className="info">
        {t("Config.ENTERPRISE_IDENTIFICATION.LOG_BUS_UNIT_SPEC_SIZE")}
      </div>

      <div className="button-container">
        <Link to={'/'}>
          <Button
            variant="outlined">
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
        </Link>
        <Button
          onClick={onSave}
          disabled={
            !(updateData.regionLogo.isUpdate || Object.keys(updateData.businessUnits).length) ||
            loading
          }
          variant="contained"
          className="save"
        >
          {t("MODAL.BUTTONS.SAVE")}
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { maxWidth: '858px', backgroundColor: bg === 'light' ? 'white !important' : '#201f1d !important' } }}
      >
        <CardMedia
          component="img"
          image={imageDisplay} />
      </Dialog>
    </div>
  )
}
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from "react-i18next";
import { CustomToolbar } from '../../components'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import Tooltip from '@mui/material/Tooltip';
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import { backend_url } from '../../settings'
import { applyFilters, dateTimeFormatter, checkSize, getParam} from '../../utils/utils'
import { useFetch } from '../../hooks'
import { CustomLoadingOverlay } from "../../components"
import CustomPanel from '../../mui-components/CustomPanel'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';


export default function SupportOutboxes() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')

  const userRoles = localStorage.getItem('userRoles')?localStorage.getItem('userRoles').split(","):''
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Outbox || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Outbox || 'm');
  
  const request = useFetch()
  
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [exportQuery, setExportQuery] = useState("")
  
  const columnVisibility = {};
  if (prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Outbox) {
    prefsData.reports_column_visibilty.Outbox.forEach(it => columnVisibility[it] = false);
  }
  
  const saveColumnVisibility = (data) => {
    saveUserPrefsWithoutRefresh({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Outbox: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
    silentUpdateUserPrefs()
  }

  const checkActionAccess = () => {
    if (userRoles?.includes("AE Full Access") || userRoles?.includes("Super User") || userRoles?.includes("System User")) {
        return true
    }
    return false
  }
  
  const additionalLines = (showBalance) => {
    if (showBalance) {
      return <><div className='trade-ballance-row'>
      </div>
        <div className='trade-ballance-row'>
        </div></>
    }
    return null
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.outboxes_filter_values,
      queryKeyToTyping: "DAG_ID", 
      title: t("Outboxes.DAG_ID"),
      inputText: t("Outboxes.INPUT"),
      firstKeyOption: "dag_id",
      secondKeyOption: "",
      valueFilter: applyFilters("DAG_ID", "outboxes"),
      changingArray: [],
      formatField: "select",
      atLeastThreeLetter: true,
    },
    {
      endpoint: backend_url.outboxes_filter_values,
      queryKeyToTyping: "DOCUMENT_ID",
      title: t("Outboxes.DOCUMENT_ID"),
      inputText: t("Outboxes.INPUT"),
      firstKeyOption: "document_id",
      secondKeyOption: "",
      valueFilter: applyFilters("DOCUMENT_ID", "outboxes"),
      changingArray: [],
      formatField: "select",
      atLeastThreeLetter: true,
    },
    {
      endpoint: backend_url.outboxes_filter_values,
      queryKeyToTyping: "STATUS",
      title: t("Outboxes.STATUS"),
      inputText: t("Outboxes.STATUS_INPUT"),
      firstKeyOption: "status",
      secondKeyOption: "",
      valueFilter: applyFilters("status", "outboxes"),
      formatField: "select",
    },
    {
      endpoint: backend_url.outboxes_filter_values,
      queryKeyToTyping: "OUTBOX_TYPE",
      title: t("Outboxes.OUTBOX_TYPE"),
      inputText: t("Outboxes.OUTBOX_TYPE_INPUT"),
      firstKeyOption: "outbox_type",
      secondKeyOption: "",
      valueFilter: applyFilters("OUTBOX_TYPE", "outboxes"),
      changingArray: [],
      formatField: "select",
    },
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {

    const changeIdRow = items.map((d) => ({
      id: `${d.dag_id}-${d.run_id}-${d.document_id}-${d.created_at}`,
      ...d,
    }))
  
    setRows((old) => {
      return [...old, ...changeIdRow]
    })
  
  }, [])


  useEffect(() => {
     
    setLoading(true)
    
    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
    }
    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField, translateJSON } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {

            if (translateJSON) {
              
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else {
              queryParams[firstKeyOption] = valueFilter
            }
            
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      outboxes: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["outboxes"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()
    
    setExportQuery(queryString)
  
    request.fetchData(
      `${backend_url.outboxes}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })
  }, [filter, page])
    
  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
      silentUpdateUserPrefs()
    })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      dag_id: {
        field: "dag_id",
        headerName: t("Outboxes.DAG_ID"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      run_id: {
        field: "run_id",
        headerName: t("Outboxes.RUN_ID"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      document_id: {
        field: "document_id",
        headerName: t("Outboxes.DOCUMENT_ID"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      outbox_type: {
        field: "outbox_type",
        headerName: t("Outboxes.OUTBOX_TYPE"),
        hideable: true,
        flex: 0.08,
        minWidth: 30,
      },
      processing_dag_id: {
        field: "processing_dag_id",
        headerName: t("Outboxes.PROCESS_NAME"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      processing_run_id: {
        field: "processing_run_id",
        headerName: t("Outboxes.PROCESS_RUN_ID"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      processing_output_id: {
        field: "processing_output_id",
        headerName: t("Outboxes.OUTPUT_ID"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
      },
      created_at: {
        field: "created_at",
        headerName: t("Outboxes.CREATED_AT"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
        renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.created_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
        
      },
      updated_at: {
        field: "updated_at",
        headerName: t("Outboxes.UPDATED_AT"),
        hideable: true,
        flex: 0.08,
        minWidth: 50,
        renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.updated_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>

      },
      status: {
        field: "status",
        headerName: t("Outboxes.STATUS"),
        hideable: true,
        flex: 0.08,
        minWidth: 30,
      },
      parameters: {
        field: "parameters",
        headerName: t("Outboxes.PARAMETERS"),
        hideable: true,
        flex: 0.08,
        minWidth: 30,
        renderCell: (params) => {
          const jsonData = params.row.parameters;
          const tooltipContent = jsonData 
            ? JSON.stringify(jsonData)
            : t("Common.noData");

          return (
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            >
              <Tooltip title={tooltipContent} placement='left'>
                <IconButton>
                  <InfoOutlinedIcon className='new' sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
              {additionalLines(params.row.parameters)}
            </div>
          )
        }
      }
    }
  
      return orderedColumns.length ? orderedColumns.filter(it => allColumns[it]).reduce((acc, field) => {
        return [...acc, allColumns[field]];
      }, []) : Object.values(allColumns);
  
    }, [orderedColumns]);
  
    const handleColumnOrderChange = React.useCallback((params) => {
        setOrderedColumns((prevOrderedColumns) => {
          const newOrderedColumns = [...prevOrderedColumns];
          const oldIndex = params.oldIndex;
          const targetIndex = params.targetIndex;
          const oldColumn = prevOrderedColumns[oldIndex];
          newOrderedColumns.splice(oldIndex, 1);
          newOrderedColumns.splice(targetIndex, 0, oldColumn);
          saveUserPrefsWithoutRefresh({
            ...prefsData,
            reports_column_order: {
              ...prefsData.reports_column_order,
              Outboxes: newOrderedColumns
            }
          })
          return newOrderedColumns;
        });
    }, []);

  const objBreadcrumb = [
      { label: t('Breadcrumb.home'), link: '/' },
      { label: t("Breadcrumb.support"), link: '' },
      { label: t("Breadcrumb.outboxes"), link: '/support/outboxes' },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Outboxes: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
              minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
              height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
          },
          '& .description-cell': {
              whiteSpace: "normal !important", 
              lineHeight: '1.5em'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
          }} 
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        initialState={{
          columns: {
            columnVisibilityModel: {
              actions: checkActionAccess(),
              ...columnVisibility
            }
          },
        }}
        localeText={{
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.outboxes"),
            displayStandartReportButton: true,
            exportSetting: {
            },
            showExport: false,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters: 
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                preload={backend_url.outboxes_filter_values}
                dataFilter={filter} 
                loading={loading}
              />,
          }
        }}
      />
    </div>
  )
}